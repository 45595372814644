<template>
  <div class="about">

    <heading titletype="titleh2"><template v-slot:text>About this website</template></heading>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing, elit. Laboriosam esse similique excepturi. Voluptate nulla, atque eaque laborum tempora minima nam repudiandae non. Quaerat dignissimos, laboriosam! Fuga odit doloremque mollitia corporis!</p>

  </div>
</template>


<script>
 import Headings from '@/components/Headings.vue';

 export default {
  name:"about",
  
  components: {
    'heading': Headings
  }
};
</script>



<style lang="scss" scoped>
</style>

