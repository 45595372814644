<template>
    <b-card class="w-50">
        <b-card-title>{{$t('auth.form.login')}}</b-card-title>
        <b-card-body>
            <form @submit.prevent="storeName">
                <label for="user">{{$t('auth.form.username')}}</label>
                <input v-model.lazy="name" id="user" type="text" class="form-control form-control-sm" :placeholder="$t('auth.form.usernamePlaceholder')">
                <button type="submit" class="btn btn-block btn-primary mt-2">{{$t('auth.form.login')}}</button>
            </form>
        </b-card-body>
    </b-card>
</template>

<script>
import example from '@/mixins/example';
export default {
    name: "LoginVuexStore",
    data() {
        return {

        }
    },
    mixins: [example],
    methods: {
        storeName () {
            this.$store.dispatch('setName', this.name);
        }
    },
    computed: {
        name: {
            get: function () {
                return this.$store.getters.getName;
            },
            set: function (newVal) {
                this.$store.dispatch('setName', newVal);
            }
        }
    },
    created() {
        console.log('login vuexstore is created');
        this.helloWorld();
    }
}
</script>

<style scoped>

</style>
