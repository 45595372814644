<template>
	<div class="page-form">

		<headings titletype="titleh2" v-if="title"><template v-slot:text>{{title}}</template></headings>
		
		<div :class="{ formgrid : grid }">
			<slot name="content"></slot>
		</div>
		<slot name="nav"></slot>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import Headings from '@/components/Headings.vue';
	export default {
		name:"PageForm",
		components: {
			Headings
		},
		props: {
			title: {
				type: String,
				required: false,
			},
			grid: {
				type: Boolean,
				required: false,
				default: false
			}
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
