<template>
	<div class="claimblocs">

		<slot name="title"></slot>

		<messages :msgType="messageType" class="col4" v-if="isThereMessage && choosenMean!=='email' && choosenMean!=='phone'" style="margin-bottom:20px;">
			<template v-slot:text>
				<ul>
					<li v-for="(message, index) in messageContent" :key="index">&bull; {{message}}</li>
				</ul>
			</template>
		</messages>


		<template v-for="(bloc, index) in blocType" :key="index">

			<div class="claimor" v-if="blocType.length >= 2 && index !== 0">{{$t('pageLitteracy.registration.or')}}</div>

			<div class="claimbloc">

				<div v-if="bloc==='email'">
					<custom-input
					fieldname="email"
					:placeholder="$t('inputLabel.email.placeholder')"
					inputType="text"
					:idLead="idLead"
					:label="$t('inputLabel.email.label')"
					:helper="$t('inputLabel.email.helper')"
					:isRequired="true"
					v-model.lazy:modelValue="email"
					ref="email"
					></custom-input>

					<messages :msgType="messageType" class="col4" v-if="isThereMessage && choosenMean==='email'" style="margin-bottom:20px;">
						<template v-slot:text>
							<ul>
								<li v-for="(message, index) in messageContent" :key="index">&bull; {{message}}</li>
							</ul>
						</template>
					</messages>

					<button @click="askCreationParticipation('email');" :disabled="isLoading">{{$t('pageLitteracy.registration.claimMail')}}</button>
				</div>

				<div v-if="bloc==='whatsapp'">
		<label for="nothing">{{$t('pageLitteracy.registration.whatsappTitle')}}</label>
					<p style="max-width:80%; margin: 0 auto 2em;">{{$t('pageLitteracy.registration.whatsappInfo')}}</p>
					<!-- Following current locale, send code bactelcaldfr or bactelcaldnl -->
					<a :href="'https://wa.me/32491900413?text=Artechol-NG-' + lang.toUpperCase()" class="btn-link" target="_blank" @click.capture="askCreationParticipation('whatsapp');" :class="{disabled:isLoading}">

						<i class="fab fa-whatsapp"></i> {{$t('pageLitteracy.registration.claimWhatsapp')}} <i class="fab fa-whatsapp"></i>
					</a>
				</div>

				<div v-if="bloc==='sms'">
<!--				<custom-input
				fieldname="phoneNumber1"
				:placeholder="$t('inputLabel.phone.placeholder')"
				inputType="text"
				:idLead="idLead"
				:label="$t('inputLabel.phone.label')"
				:helper="$t('inputLabel.phone.helper')"
				:isRequired="true"
				v-model.lazy:modelValue="sms"
				ref="phoneNumber1"
				></custom-input>-->

				<phone-input
				fieldname="phoneNumber1"
				v-model="smsHelper"
				inputType="phone"
				:idLead="idLead"
				:placeholder="$t('inputLabel.phone.placeholder')"
				:label="$t('inputLabel.phone.label')"
				:helper="$t('inputLabel.phone.helper')"
				:isRequired="true"
				@internationalNumber="setPhone"
				ref="phoneNumber1"
				></phone-input>

				<messages :msgType="messageType" class="col4" v-if="isThereMessage && choosenMean==='phone'" style="margin-bottom:20px;">
					<template v-slot:text>
						<ul>
							<li v-for="(message, index) in messageContent" :key="index">&bull; {{message}}</li>
						</ul>
					</template>
				</messages>

				<button @click="askCreationParticipation('phoneNumber1');" :disabled="isLoading">{{$t('pageLitteracy.registration.claimSms')}}</button>

			</div>

		</div>
	</template>
</div>
</template>

<!-- ================================================================================== -->

<script>
	import CustomInput from "@/components/CustomInput";
	import Messages from '@/components/Messages.vue';
	import PhoneInput from "./PhoneInput";

	export default {
		name:"ClaimBlocs",

		components: {
			CustomInput,
			Messages,
			PhoneInput
		},

		props: {
			blocType : {
				type: [Array]
			},
			idLead: {
				type: Number,
				required: true,
			},
			lang : {
				type: String,
				required: false
			}

		},
		data(){
			return {
				email : '',
				smsHelper: '',
				sms: '',
				whatsapp: '',
				choosenMean : '',
				isLoading : false,

				isThereMessage: false,
				messageContent: [],
				messageType: "alert",
			}
		},

		methods : {
			setPhone(number) {
				console.log(number);
				this.sms = number;
			},

			leaveThisPage(){
				console.log("I leave the inscription page");
				this.$emit('goto', 'confirminscription');
			},

			check(fieldname){
				let el = document.getElementById(fieldname);
				if(el.classList.contains('hasSuccess')){
					console.log("Le champ " + fieldname + " a la bonne classe");
					return true;
				}else{
					console.log("Le champ est vide ou en erreur");
					this.$refs[fieldname].validateMyself();
					return false;
				}
			},

			askCreationParticipation(fieldname) {
				/* changing the button status */
				this.isLoading = true;

				console.group("CreateParticipationOwner");
				console.log("I asked to create my participation ownership");
				if(fieldname === "phoneNumber1" && this.check(fieldname)){
					console.log("…by phone");
					this.axiosPart(this.sms, fieldname);
					this.choosenMean = 'phone';
				}
				else if(fieldname === "email" && this.check(fieldname)){
					console.log("…by email");
					this.axiosPart(this.email, fieldname);
					this.choosenMean = 'email';
				}
				else if(fieldname === "whatsapp"){
					console.log("…by whatsapp");
					this.choosenMean = 'whatsapp';
					this.leaveThisPage();
				}else{
					this.isLoading = false;
				}
				// HERE, each field has been checked, if needed an error message is displayed. Nothing else happens.
			},




			/* THIS IS NOT DRY => USE STORE */
			/* ==== IDLANGUAGECODE & IDCOUNTRYCODE ==== */
			getIdOfLanguage(){
				/* Must return the id based on the currentLocale */
				let defaultIdLang = 2;
				let idLang = defaultIdLang;
				let currentLocale = this.$i18n.locale.slice(0,2).toUpperCase();
				/* this must be replaced by a comparaison with data received from API => See getLocalizingData() */
				if (currentLocale === 'FR'){
					idLang = 1;
				}else{
					idLang = defaultIdLang;
				}
				/* END */
				return idLang;
			},
			/* ==== END IDLANGUAGECODE & IDCOUNTRYCODE ====*/



			axiosPart(wayToContact, fieldname) {
				// set the loading state for button (to be created)
				this.isLoading = true;

				let test = this.idLead;
				console.log(test);
				let data = {
					"idLeadManagement": test, // XXXX because "this" scope ?
					"lang": this.$i18n.locale.slice(0,2),
					[fieldname] : wayToContact,
					"idLanguageCode" : this.getIdOfLanguage(),
					"idCountryCode" : 1,
				};

				this.axios.post(
					`/participationOwner`,
					data
					)
				.then(res => {
					console.log("I managed to create the participation! ");
					console.log(res);
					console.groupEnd();
					this.$emit('mean', this.choosenMean);
					this.leaveThisPage();
				})
				.catch((error) => {
					console.error("I'm sorry Dave, I'm afraid I can't do that");
					console.log(error); // for linter to be happy
					console.log(error.response.data);
					console.log("------");
					console.log(error.response.data.exception);
					console.log(error.response.data.message);
					console.groupEnd();

					/* Better to display the error inside the div of the concerned field */
					/* => must use this.choosenMean to determine witch field is "in error" */
					/* => programmatically set the error on this field, and display the notification(s) there */

					// Toaster
					if(error.response.data.notifications && error.response.data.notifications.length > 0){
						this.displayMessage(error.response.data.notifications, 'alert');
					}
					// There is no "notifications" in the 404 error. So, I added this:
					else if(error.response.data.message && error.response.data.message.length > 0){
						this.displayMessage(error.response.data.message, 'alert');
					}
					this.isLoading = false;
				});
			},

			displayMessage(message, mode) {
				console.log("Displaying message");
				this.isThereMessage = true;
				this.messageContent = message;
				this.messageType = mode;
			},

			hideMessage() {
				this.isThereMessage = false;
			},
		}
	}

</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
