<template>

	<div id="layoutGen">

		<!-- =============== FORM =============== -->
		<div id="formWrapper">
			<div id="contentColumn" class="action-wrapper">

				<lang-switcher></lang-switcher>






				<!-- ========== LOGO ========== -->
				<div id="logo">
					<img src="@/assets/img/astel-medica_logo.svg" alt="Logo Astel Medica">
				</div>
				<!-- ========== // ========== -->







				<!-- ========== HEADER ========== -->
				<div id="header">
					<div class="titleh1">
						<div class="title">
							<img src="./assets/img/artechol.svg" alt="">
						</div>
						<div class="subtitle">{{$t('product.subtitle')}}</div>
						<div class="subsubtitle">{{$t('product.subsubtitle')}}</div>
					</div>
				</div>
				<!-- ========== // ========== -->




				<!-- FORM HEADER -->
				<div id="formHeader">
					<div class="page-title" v-html="$t('pageTitles.registration')"></div>

					<div class="illu col4"></div>

					<div id="group_packshot">
						<div id="packshot-shadow"></div>
						<div id="macarons" :class="{'nl' : this.$i18n.locale === 'nl_BE', 'fr' : this.$i18n.locale !== 'nl_BE'}">
							<div id="macaron"></div>
						</div>
						<div id="packshot" :class="{'nl' : this.$i18n.locale === 'nl_BE', 'fr' : this.$i18n.locale !== 'nl_BE'}"></div>
					</div>
				</div>






				<!-- ========== FORM ========== -->
				<div id="formPaper">
					<router-view></router-view>
				</div>
				<!-- ========== // ========== -->







				<!-- ========== FOOTER ========== -->
				<div id="footer">
					<ul>
						<li>© Promolife</li>
						<!-- Link must be dynamic following the language -->

						<!--
						<li><a :href="'./files/conditions_' + $i18n.locale.toLowerCase() + '.pdf'" download>General Terms and Conditions</a></li>
					-->
					<!-- This could be a solution… -->
					<li><a href="Terms and conditions" @click.prevent="openConditions()" download>{{$t('footer.genterms')}}</a></li>
					<!-- // -->
					<li>
						<a v-if="this.$i18n.locale === 'nl_BE'" href="mailto:infolijn@promolife.be">infolijn@promolife.be</a>
						<a v-if="this.$i18n.locale != 'nl_BE'" href="mailto:infoligne@promolife.be">infoligne@promolife.be</a>
					</li>
					<li><a href="tel:+3223520398">+32 2 352 03 98</a></li>
				</ul>
			</div>
			<!-- ========== // ========== -->



		</div> <!-- END #content-->
	</div> <!-- END #right -->
</div> <!-- ===== END .layoutGen ===== -->





<!-- GDPR -->
<gdpr
:client="$t('gdpr.client')"
:client-adress="$t('gdpr.adress')"
contact-phone=""
contact-name=""
contact-email=""
@gdprClosing="insertPixel">
</gdpr>





</template>







<script>

	// import Headings from '@/components/Headings.vue';
	// import AppNav from '@/components/AppNav'; /* navigation between pages, not inside form */
	import GDPR from '@/components/GDPR.vue';
	import LangSwitcher from '@/components/LangSwitcher.vue';
	import pixel from "./mixins/pixel";
	import ga from "./mixins/ga";
	import cf from "./mixins/cf";

	export default {
		title: 'Astel Medica | Artechol NG',
		name: 'App',
		data() {
			return {
			}
		},
		components: {
			LangSwitcher,
			'gdpr': GDPR
		},
		mixins: [pixel, ga, cf],
		methods: {
			openConditions (){
				let link = "https://cashback.artechol-ng.com/files/conditions_";
				window.open(link + this.$i18n.locale.toLowerCase() +".pdf");
			},

			getLangFromUrl () {
				this.$i18n.locale = "nl_BE";
				let urlParams = new URLSearchParams(window.location.search);
				let lang = '';
				if (urlParams.has('lang')){
					lang = urlParams.get('lang');
					if (lang.toLowerCase() === "fr" || lang.toLowerCase() === "fr_be" || lang.toLowerCase() === "be_fr"){
						this.$i18n.locale = "fr_BE";
					}
				}
			},

			insertPixel() {
				this.deployAndLog();
			}

		},
		beforeMount(){
			this.getLangFromUrl();
		},
	}

</script>


<style lang="scss">
</style>



