<template>
	<div class="titlewrapper" :class="titletype">
		<div>
			<slot name="text"></slot>
		</div>
	</div>
</template>

<!-- ========================================= -->

<script>
	export default {
		name: "heading",

		props : {
			titletype : {
				type: String,
				required: true
			}
		}
	}
</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>