// Cookiefirst
export default {

	methods: {
		putCookieFirstScriptInPlace(){
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);
			script.setAttribute('data-cookiefirst-key',`4ae0d3dd-037b-4154-8545-d0fdda0f00cf`);
			document.body.prepend(script);
		}
	},
	beforeMount() {
		this.putCookieFirstScriptInPlace();
	}

}
