export const nl = {

	gdpr: {
		'title': "Privacy notice",
		client: "Astel Medica",
		adress: "Rue du Zoning 5, 4557 TINLOT B"
	},

	maintenance: {
		title: "Onderhoud",
		line1 : "Deze website is momenteel in onderhoud en kan niet worden geraadpleegd. Probeer het later nog eens.",
		line2 : "Ons team doet er alles aan om de site zo snel mogelijk weer toegankelijk te maken.",
		thanks : "Dank u voor uw begrip.",
	},


	footer: {
		genterms: "Algemene voorwaarden",
	},

	auth: {
		form: {
			login: 'Aanmelden',
			username: 'Gebruikersnaam',
			usernamePlaceholder: 'Geef uw gebruikersnaam in...',
			password: 'Wachtwoord',
		}
	},

	navigation: {
		doSurvey: "Antwoorden op de enquête",
		skipSurvey: "Cashback aanvragen",
		previous: "Vorige pagina",
		next: "Volgende pagina",
		claimCashback: "Mijn Cashback aanvragen",
		signup: "Schrijf je nu in!",
		toCustomerWebsite: "Ontdek Artechol NG",
	},

	breadcrumbSteps: {
		registration: "Inschrijven ",
		store: "Doe uw aankopen",
		form: "Vul het formulier in",
		survey: "Vul een optionele vragenlijst in",
		cashback: "Ontvang uw terugbetaling"
	},

	product: {
		subtitle : "Controleer uw cholesterol.",
		subsubtitle : "De toekomst is aan jou."
	},

	pageTitles: {
		maxReached: "Maximum aantal inzendingen bereikt",
		wrongCode: "Verkeerde code",
		registration: "Schrijf u in en ontvang uw Cashback!",
		alreadySend: "Deelname reeds verstuurd",
		confirmation: "Bevestiging",
		beforeStart: "Deze actie is nog niet beschikbaar.",
		actionEnded: "Deze actie is helaas afgelopen.",
	},

	sectionTitles: {
		inscription: "Schrijf u hier in",
	},

	pageLitteracy: {
		maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",
		wrongCode: "De opgegeven code is verkeerd. U moet de link gebruiken die u door Astel Medica wordt toegestuurd. Maar misschien wil je je inschrijven voor de actie?",
		alreadySend: "U heeft uw deelname al verzonden",
		registration : {
			byRegistering: "Door me in te schrijven voor deze actie, ga ik akkoord met de",
			privacyNotice: "Privacy notice",
			claimMail : "Inschrijven via e-mail",
			claimWhatsapp: "Inschrijven via Whatsapp",
			claimSms: "Inschrijven via SMS",
			or: "Of",
			whatsappTitle: "Klik op de Whatsapp knop",
			whatsappInfo: "Een venster wordt geopend en u wordt gevraagd om het woord Artechol-NG-NL van uit uw Whatsapp te verzenden.",

		},
		confirmInscription: {
			thanks: "Bedankt voor uw inschrijving!",
			smsLine1 :"We hebben je net een SMS gestuurd met alle details.",
			smsLine2: "Ga eens kijken!",
			smsLine3: "Opgepast : Deze actie is beperkt tot 750 deelnames. Wacht dus niet te lang om deel te nemen.",
			whatsappLine1: "Controleer uw telefoon.",
			whatsappLine2: "Alle instructies zijn verstuurd via Whatsapp",
			whatsappLine3: "Opgepast : Deze actie is beperkt tot 750 deelnames. Wacht dus niet te lang om deel te nemen.",
			mailLine1: "We hebben je net een e-mail gestuurd met alle details.",
			mailLine2: "Ga eens kijken!",
			mailLine3: "Opgepast : Deze actie is beperkt tot 750 deelnames. Wacht dus niet te lang om deel te nemen.",
		},
		landing: {
			mainTitle: "Ontvang uw cashback nu!",
			aboutYou: "Vertel ons meer over u",
			aboutProduct: "Over ons product",
			popTitle : "Aankoopbewijs",
			
		},
		confirmation: {
			title: "Bevestiging",
			textes: {
				p1: "Wij danken u voor uw deelname aan de Artechol NG actie.",
				p2: "U ontvangt uw terugbetaling binnen de 6 weken, mits u aan de algemene voorwaarden heeft voldaan.",
				p3: "Met vriendelijke groeten,",
				p4: "Het Artechol NG team"
			},
		},
		actionEnded: {
			text: "Bezoek onze website regelmatig om op de hoogte te blijven van toekomstige acties."
		},
		beforeStart: {
			text: "U krijgt toegang vanaf "
		},
	},






	inputLabel: {
		defaultListHelper: "Kies er één uit de lijst",
		mandatoryFields: "Verplichte velden",

		optin : {
			label : "Ik ontvang graag informatie met betrekking tot toekomstige acties van Astel Medica.",
			helper : "",
		},

		conditions : {
			part1 : "Ik aanvaard",
			link: "de algemene voorwaarden",
			part3: ""
		},

		title : {
			label : "Aanspreking",
			placeholder : "Hoe kunnen we u noemen?",
			helper : "",
		},
		firstname: {
			label: "Gelieve uw voornaam in te vullen",
			placeholder : "Voornaam",
			helper: "",
		},
		lastname: {
			label: "Gelieve uw naam in te vullen",
			placeholder : "Achternaam",
			helper: "",
		},
		email : {
			label: "Gelieve uw e-mail in te vullen",
			placeholder: "sauron318{'@'}voorbeeld.be",
			helper: ""
		},
		phone : {
			label: "Gelieve uw GSM-nummer in te vullen",
			placeholder: "Vb: 0477123456",
			helper: ""
		},
		iban : {
			label: "Gelieve uw IBAN nummer te vullen",
			placeholder: "bv: BE00123000456000",
			helper: "Het nummer moet beginnen met BE of LU"
		},
		product: {
			label: "Welk product heeft u aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: ""
		},
		dropdown : {
			placeholder: "Kies een antwoord…",
			helper: ""
		},
		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},


      //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation

/*
      typePassword : "Gelieve je wachtwoord in te vullen",
      helpPassword : "Je wachtwoord moet X, Y en Z bevatten",
      typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
      */

      upload: {
      	state: "status",
      	uploadYourFile: "Upload bestand",
      	clickOrDrop: "Klik of sleep je ticket hier.",
      	remove: "Verwijder",
      	waiting: "Wachten op upload...",
      	uploaded: "Geüpload!",
      	error: "Mislukt",
      	uploadsDone: "Upload succesvol afgerond",
      	onlyImages: "Enkel afbeeldingen of PDFs worden aanvaard. ",
      	maxSize: "Maximale grootte van 20mb",
      	uploadFile: "Upload bestand",
      	generalError: "Er ging iets mis met uw upload, gelieve later opnieuw te proberen"
      }
},





temporary: {
	leadTMD1: {
		label: "Waar heeft u uw product aangekocht?",
		placeholder: "Kies een antwoord",
		helper: ""
	},
	leadTMD1alt: {
		label: "Welke andere plaats?",
		placeholder: "",
		helper: ""
	},
	leadTMD2: {
		label: "Hoe heeft u van ons product gehoord?",
		placeholder: "Kies een antwoord",
		helper: ""
	},
	leadTMD2alt: {
		label: "Hoe heeft u anders van ons product gehoord?",
		placeholder: "",
		helper: ""
	},
	leadTMD3: {
		label: "Hoe waarschijnlijk zou u dit product aanbevelen aan anderen?",
		placeholder: "Kies een antwoord",
		helper: "1= zeer onwaarschijnlijk, 10 = zeer waarschijnlijk"
	}
}
}
