<template>
	<div class="groupIcon">
		
		<div class="iconWrapper">
			<img class="icon" :src="getUrl(stepName)" alt="">
			<span v-if="isValid" class="pastille valid">&nbsp;</span>
			<span v-if="!isValid" class="pastille">{{stepNumber + 1}}</span>
		</div>

		<span class="label">{{$t('breadcrumbSteps.' + stepName)}}</span>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Crumbs",
		props: {
			stepName : {
				type: String,
				required: true
			},
			stepNumber : {
				type: Number,
				required: true
			},
			isValid : {
				type: Boolean,
				required: true
			}
		},
		methods: {
			getUrl(name) {
				return require('@/assets/img/ico_' + name + '.png');
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
	$small_threshold : 560px;
	$medium_threshold : 860px;
	$big_threshold : 1040px;

	.groupIcon {
		display: flex;
		gap:14px;
		margin:0 auto;

		@media screen and (min-width : $small_threshold) {
			width:180px;
		}

		.iconWrapper {
			position: relative;
		}
		.icon {
			margin: 0 auto;
		}

		@media screen and (max-width : $small_threshold) {
			margin-bottom:1em;
			flex-direction: column;
			text-align: center;
			.icon {
				width:45px;
				height:45px;
			}
			span.label {
				font-size:12px;
				max-width:120px;
			}
		}
		@media screen and (min-width : $small_threshold) {
			flex-direction: row;
			text-align: left;
			margin-bottom:1.5em;
			.iconWrapper {
				margin-right:1.5em;
			}
			.icon {
				width:65px;
				height:65px;
			}
			span.label {
				font-size:0.9em;
			}

		}
		@media screen and (min-width : $medium_threshold) {
			flex-direction: column;
			text-align: center;
			.icon {}
			span.label {}
			.iconWrapper {
				margin-right:0;
			}
		}
		@media screen and (min-width : $big_threshold) {
			.icon {}
			span.label {}
		}

	}

	.label {
		justify-self: start;
		align-self: center;
		line-height:1.2em;
		@media screen and (max-width : $small_threshold) {
			display: inline-block;
			margin-top:0.5em;
		}
		@media screen and (min-width : $small_threshold) {
			padding:0;
		}
	}

	span.pastille {
		position: absolute;
		bottom:-2px;
		padding:0 !important;
		display: inline-block;

		width:24px;
		border-radius:400px;
		line-height:24px;
		text-align: center;
		font-weight: bold;
		font-size:1rem;
		@media screen and (max-width : $small_threshold) {
			right:50%;
			transform: translateX(50px);
		}
		@media screen and (min-width : $small_threshold) {
			right:50%;
			transform: translateX(40px);
		}
	}
	span.pastille.valid {
		background: url('../assets/img/tick-mark.svg') no-repeat center center / cover;
	}
</style>
