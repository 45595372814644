// Facebook pixel code
// NOTE : it is called when user close the GDPR banner.
// See App.vue > GDRP
export default {
	
	methods: {
		putScriptInPlace(){
			console.log('=================script deployed=================')
			let script = document.createElement('script');
			script.innerHTML = `
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '256937906375162');
				fbq('track', 'PageView');
				`;
				let noscript = document.createElement('noscript');
				noscript.innerHTML = `<img height="1" width="1" style="display:none"
				src="https://www.facebook.com/tr?id=256937906375162&ev=PageView&noscript=1"
				/>`;
				document.body.prepend(noscript);
				document.body.prepend(script);
			},
			deployAndLog() {
				/*
				this.putScriptInPlace();
				// eslint-disable-next-line no-undef
				fbq('track', 'PageView');
				*/
			},
			logCustomEvent(page) {
				if (typeof fbq === 'function') {
					// eslint-disable-next-line no-undef
					fbq('trackCustom', 'Page-Detail', {
						detail: page
					});
				}
			}
		}
		
	}
