export default {
    apiUrl: process.env.VUE_APP_BACKENDURL,
    idlead: process.env.VUE_APP_IDLEAD,

    allowMaintenanceCheck: process.env.VUE_APP_ALLOWMAINTENANCECHECK,

    clientId: process.env.VUE_APP_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    
    enquete: {
        question1: process.env.VUE_APP_QUESTION1,
        othervalue1: process.env.VUE_APP_OTHERVALUE1,
        question2: process.env.VUE_APP_QUESTION2,
        othervalue2: process.env.VUE_APP_OTHERVALUE2,
        question3: process.env.VUE_APP_QUESTION3,
    }
}
