<template>
	<div class="infobox" :class="{'info':msgType==='info', 'alert':msgType==='alert'}">
		<div class="icon">
			
			<i class="fas fa-info-circle" v-if="msgType==='info'"></i>
			<i class="fas fa-exclamation-triangle" v-if="msgType==='alert'"></i>

		</div>
		<div class="box">
			<slot name="text"></slot>
		</div>
		
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"Message",
		props: {
			msgType : {
				type: String, 
				required: false,
				default: "info"
			}
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

	.infobox {
		margin: 0 auto;
		display: grid;
		grid-template-columns: 40px 1fr;
		border:1px solid #000;
		border-radius: 8px;
		background: #fff;

		.icon {		
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			text-align:center;
			line-height:40px;
			font-size:25px;
		}

		.box {
			padding: 0.5em 1em;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			line-height:1.4em;
		}

		&.info {
			.icon {
				color: #9c0;
			}
			.box {
				background: #0601;
			}
		}
		&.alert {
			.icon {
				color: #F33;
			}
			.box {
				background: #c002;
			}
		}

	}


</style>
