<template>

    <div>
        <headings titletype="titleh2"><template v-slot:text>Components in dev</template></headings>


        <section>
            <headings titletype="titleh3"><template v-slot:text>Own Upload file</template></headings>
            <fileuploader label="Upload your proof of purchase here"></fileuploader>
        </section>

        <section>
            <headings titletype="titleh3"><template v-slot:text>Vue-upload-component</template></headings>
            <new-upload-file label="Upload your proof of purchase here" fieldname="test"></new-upload-file>
        </section>


    </div>
</template>

<script>
   // import LoginVuexStore from "@/examples/LoginVuexStore";
   import Headings from '@/components/Headings.vue';

   
   export default {
    name: "Home",
    components: {
           // LoginVuexStore,
           Headings,
       },
   }
</script>

<style>
</style>
