export const fr = {

	gdpr: {
		'title': "Avis de confidentialité",
		client: "Astel Medica",
		adress: "Rue du Zoning 5, 4557 TINLOT B"
	},

	maintenance: {
		title: "Maintenance",
		line1 : "Ce site web est actuellement en maintenance et ne peut pas être accédé. Réessayez plus tard.",
		line2 : "Notre équipe fait tout pour que le site soit de nouveau accèssible au plus vite.",
		thanks : "Merci de votre compréhension.",
	},


	footer: {
		genterms: "Conditions générales",
	},

	auth: {
		form: {
			login: "Login",
			username:"Nom d'utilisateur",
			usernamePlaceholder: "Indiquez votre nom d'utilisateur",
			password: "Mot de passe",
		}
	},

	navigation: {
		doSurvey: "Répondre à l'enquête",
		skipSurvey: "Demander le cashback",
		previous: "Page précédente",
		next: "Page suivante",
		claimCashback: "Demander mon cashback",
		signup: "M'inscrire !",
		toCustomerWebsite: "Découvrir Artechol NG",
	},

	breadcrumbSteps: {
		registration: "Enregistrez-vous",
		store: "Faites vos achats",
		form: "Remplissez le formulaire",
		survey: "Répondez à un questionnaire optionel",
		cashback: "Recevez votre remboursement"
	},

	product: {
		subtitle : "Contrôlez votre cholesterol.",
		subsubtitle : "Le futur vous appartient."
	},

	pageTitles: {
		maxReached: "Maximum de participations atteint",
		wrongCode: "Mauvais code",
		registration: "Inscrivez-vous et obtenez votre cashback&nbsp;!",
		alreadySend: "Participation déjà envoyée",
		confirmation: "Confirmation",
		beforeStart: "Cette action n'est pas encore disponible.",
		actionEnded: "Cette action est terminée.",
	},

	sectionTitles: {
		inscription: "Inscrivez-vous ici",
	},

	pageLitteracy: {
		maxReached : "Cette action est terminée car elle a atteint son maximum de participations.",
		wrongCode: "La clé fournie est erronée. Vous devez utiliser le lien que vous a envoyé Astel Medica. Mais peut-être souhaitez-vous vous inscrire à l'action ?",

		alreadySend: "Vous avez déjà envoyé votre participation.",
		registration : {
			byRegistering: "En m'inscrivant à cette action, j'accepte ",
			privacyNotice: "la politique en matière de vie privée",
			claimMail : "S'inscrire par e-mail",
			claimWhatsapp: "S'inscrire par Whatsapp",
			claimSms: "S'inscrire par SMS",
			or: "Ou",
			whatsappTitle: "Cliquez sur le bouton Whatsapp",
			whatsappInfo: "Une fenêtre s'ouvrira, vous serez ensuite invité à envoyer le mot Artechol-NG-FR depuis votre Whatsapp.",

		},
		confirmInscription: {
			thanks: "Merci pour votre inscription !",
			smsLine1 :"Nous venons de vous envoyer un SMS avec tous les détails.",
			smsLine2: "Aller voir !",
			smsLine3: "Attention : Cette action est limitée à 750 participations. Ne tardez donc pas à participer.",
			whatsappLine1: "Vérifiez votre téléphone",
			whatsappLine2: "Toutes les informations ont été envoyées par Whatsapp",
			whatsappLine3: "Attention : Cette action est limitée à 750 participations. Ne tardez donc pas à participer.",
			mailLine1: "Nous venons de vous envoyer un e-mail avec tous les détails.",
			mailLine2: "Découvrez cet e-mail !",
			mailLine3: "Attention : Cette action est limitée à 750 participations. Ne tardez donc pas à participer.",
		},
		landing: {
			mainTitle: "Demandez votre cashback maintenant !",
			aboutYou: "Dites-nous en plus à votre propos",
			aboutProduct: "À propos de notre produit",
			popTitle : "Preuve d'achat",			
		},
		confirmation: {
			title: "Confirmation",
			textes: {
				p1: "Nous vous remercions pour votre participation à l'action Artechol NG.",
				p2: "Vous recevrez votre remboursement endéans les 6 semaines pour autant que vous ayez rempli les conditions générales.",
				p3: "Bien à vous,",
				p4: "L'équipe Artechol NG"
			},
		},
		actionEnded: {
			text: "N'hésitez pas à visiter régulièrement notre site pour vous tenir informé des actions futures."
		},
		beforeStart: {
			text: "Vous pourrez y accéder à partir du "
		},
	},





	inputLabel: {
		defaultListHelper: "Choisissez une proposition dans la liste",
		mandatoryFields: "Champs obligatoires",

		optin : {
			label : "Envoyez-moi plus d'information à propos des actions Astel Medica",
			helper : "",
		},

		conditions : {
			part1 : "J'accepte les",
			link: "conditions générales",
			part3: ""
		},

		title : {
			label : "Titre",
			placeholder : "Comment pouvons-nous vous appeler ?",
			helper : "",
		},
		firstname: {
			label: "Indiquez votre prénom",
			placeholder : "Prénom",
			helper: "",
		},
		lastname: {
			label: "Indiquez votre nom",
			placeholder : "Nom",
			helper: "",
		},
		email : {
			label: "Indiquez votre e-mail ici",
			placeholder: "sauron318{'@'}exemple.be",
			helper: ""
		},
		phone : {
			label: "Indiquez votre numéro de mobile",
			placeholder: "Ex : 0477123456",
			helper: ""
		},
		iban : {
			label: "Indiquez votre numéro IBAN",
			placeholder: "Ex : BE00123000456000",
			helper: "Le numéro doit commencer par BE ou LU"
		},
		product: {
			label: "Quel produit avez-vous acheté ?",
			placeholder: "Choisissez une réponse…",
			helper: ""
		},
		dropdown : {
			placeholder: "Choisissez une réponse…",
			helper: ""
		},
		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},

        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation


        
/*
        typePassword : "Indiquez votre mot de passe",
        helpPassword : "Votre mot de passe doit contenir X, Y et Z",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        */


        upload: {
        	state: "statut",
        	uploadYourFile: "Envoyer vos fichiers",
        	clickOrDrop: "Cliquez ou déposer votre ticket ici.",
        	remove: "Retirer",
        	waiting: "En attente de l'envoi…",
        	uploaded: "Envoyé !",
        	error: "Echoué",
        	uploadsDone: "Upload réussi",
        	onlyImages: "Seulement des images ou des pdf sont acceptés.",
        	maxSize: "Taille maximum de 20 Mo.",
        	uploadFile: "Télécharger le fichier",
        	generalError: "Erreur, veuillez ressayer plus tard"
        }
},



temporary: {
	leadTMD1: {
		label: "O\u00f9 avez-vous achet\u00e9 le produit ?",
		placeholder: "Choisissez une réponse",
		helper: ""
	},
	leadTMD1alt: {
		label: "Quel autre magasin ?",
		placeholder: "",
		helper: ""
	},
	leadTMD2: {
		label: "Via quel canal avez-vous entendu parler de notre produit ?",
		placeholder: "Choisissez une réponse",
		helper: ""
	},
	leadTMD2alt: {
		label: "Par quel autre moyen avez-vous entendu parler de notre produit ?",
		placeholder: "",
		helper: ""
	},
	leadTMD3: {
		label: "Dans quelle mesure recommanderiez-vous ce produit \u00e0 d'autres personnes ?",
		placeholder: "Choisissez une réponse",
		helper: "1 = tr\u00e8s improbable, 10 = extr\u00eamement probable"
	}
}
}
