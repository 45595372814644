<template>
	<div class="aCustomSelect field" key="fieldname">

		<!-- Label -->
		<label :for="fieldname">{{metaData.description}}&nbsp;<span v-if="isRequired" class="req">*</span></label>


		<!-- Dropdown -->
		<div class="dropdown" :class="{'open': isListOpen}">

			<input
			type="text"
			autocomplete = "off"
			:id="fieldname"
			:placeholder="placeholder"
			v-model="textInField"
			@input="userInputChanged"
			@keyup.prevent.down="down"
			@keyup.prevent.up="up"
			@keyup.prevent.enter="enter"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			>

			<!-- Toggler -->
			<div class="toggle" @click="displaySuggestions(!isListOpen)">
				<span class="arrow-up" v-if="isListOpen">▲</span>
				<span class="arrow-down" v-if="!isListOpen">▼</span>
			</div>

			<!-- Suggestions list -->
			<ul class="suggestion-list">
				<li
				v-for="(item, index) in optionsList"
				:key="index"
				:class="{'active': currentActiveSuggestion === index}"
				@click="selectSuggestion(item, index)"
				>
				{{item.description}}
			</li>
		</ul>

	</div>
	<!-- END Dropdown -->


	<!-- Displaying an error message -->
	<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>

	<!-- Helper for this input -->
	<div class="helper">{{helper}}</div>

</div>
</template>































<script>

	import FormFields from "../mixins/FormFields";

	export default {
		name:"customSelect",
		mixins: [FormFields],

		props : {
			dataFrom : {
				type: String,
				required: true
			},
			modelValue: {
				required: false,
				// This IS received in case of reparticipation…
			},
			isReparticipation: {
				type: Boolean,
				required: true,
			}
		},

		data() {
			return {
				metaData: [{
					leadmanagementmetadata: {
						description: "",
						leadmanagementmetadatavalue: []
					}
				}],
				isListOpen: false,
				currentActiveSuggestion: 0,
				errorMessage: '',
				isInError: false,
				searchText: '',
			}
		},

		computed: {

			textInField: {
				get(){
					return this.searchText;
				},
				set(str){
					this.searchText = str;
				}
			},

			optionsList(){
				let list = this.metaData.leadmanagementmetadatavalue;
				/* Ajouter un peu de nettoyage (trim) des descriptions */
        if(typeof list !== "undefined") {
          for (let item of list){
            item.description = item.description.trim();
          }
        } else {
          return [];
        }
				return list;
			},

		},

		methods: {
			/* Navigation by key inside the input */
			/* A key is pressed */
			up() {
				console.log("I pressed up");
				if(this.isListOpen && this.currentActiveSuggestion > 0){
					this.upOneItem();
				}else{
					this.displaySuggestions(false);
					this.currentActiveSuggestion = 0;
				}
			},
			down(){
				console.log("I pressed down");
				if(this.isListOpen && this.currentActiveSuggestion < this.optionsList.length -1){
					this.downOneItem();
				}else if(!this.isListOpen){
					this.displaySuggestions(true);
					this.currentActiveSuggestion = 0;
				}
			},

			enter(){
				console.log("I pressed enter");
				if( !this.checkIfImValid() ){
					this.setFieldInError("ERROR MESSAGE TO BE CHANGED");
				}else{
					this.setSelection(this.optionsList[this.currentActiveSuggestion]);
				}
				this.checkIfImValid();
				this.displaySuggestions(false);
			},

			isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
				let listSuggestions = [];
				for(let item of this.optionsList){
					listSuggestions.push(item.description.trim().toLowerCase());
				}
				return listSuggestions.includes(this.searchText.trim().toLowerCase());
			},

			checkIfImValid(){
				console.log("TODO : I check the validity of the field and give it it's color");
				if(this.isThisFieldValid()){
					console.log("This field " + this.fieldname + " IS valid.");
					this.giveSuccess();
				}else{
					console.log("This field " + this.fieldname + " IS NOT valid.");
					this.giveError();
				}
				this.sendCheckSignal(this.isThisFieldValid());
				return this.isThisFieldValid();
			},

			setFieldInError(msg="Entry is invalid"){
				this.errorMessage = msg;
				// this.checkIfImValid();
			},

			setSelection(data){
				try{
					console.log("Je set la sélection");
					console.log(data);
					this.$emit('update:modelValue', data.id);
					this.textInField = data.description;
					this.sendCheckSignal(true);
					this.checkIfImValid();
				}catch(e){
					console.log(e);
				}
			},

			/* What to do when a key is pressed */
			upOneItem(){
				console.log("Up one item");
				if(this.currentActiveSuggestion > 0){
					this.currentActiveSuggestion -= 1;
				}
			},
			downOneItem(){
				console.log("down one item");
				if(this.currentActiveSuggestion < this.optionsList.length -1){
					this.currentActiveSuggestion += 1;
				}
			},

			/* Other stuff */
			selectSuggestion (suggestion, index) {
				this.closeList();
				this.searchText = suggestion.description;
				this.$emit('update:modelValue', suggestion.id);
				this.currentActiveSuggestion = index;
				this.sendCheckSignal(true);
				this.checkIfImValid();
			},

			sendCheckSignal(bool){
				// this.checkIfImValid(); // not yet declared
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			userInputChanged() {
				if (!this.isListOpen) {
					this.openList();
				}
			},

			openList(){
				/* to "refresh" the content of the list following current locale */
				this.removeText();
				this.getMetaDataFromAPI();
				this.isListOpen = true;
			},
			closeList(){
				this.isListOpen = false;
			},

			removeText(){
				this.currentActiveSuggestion = '';
				this.searchText = "";
				this.textInField = "";
			},

			displaySuggestions(bool){
				/* bool === true ? => open list */
				/* bool === false ? => close list */
				if(bool){
					this.openList();
				}else{
					this.closeList();
				}
				console.log("Displaying options");
			},

			getMetaDataFromAPI(){
				this.setLoading();
				this.axios.get(`/dropdown/${this.idLead}/metadata/${this.dataFrom}`)
				.then(res => {
					console.log("API Call: success");
					/* Filtering data to keep the one we need for this instance of the component: */
					this.metaData = res.data;
				})
				.catch(error => {
					console.log("API Call: failed");
					console.log(error);
				})
				.then(() => {
					console.log("Appel API terminé");
				});
				this.setNotLoading();
			}
		},

		created() {
			this.getMetaDataFromAPI();
		},
		mounted(){
		}
	}

</script>


































<style lang="scss" scoped>

	.dropdown {
		display: inline-block;
		position: relative;
	}

	.suggestion-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 2;
		max-height:15em;
	}

	.dropdown .suggestion-list {
		display: none;
	}

	.dropdown.open .suggestion-list {
		display: block;
	}

	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-60%);
		right:1.25em;
		cursor: pointer;
	}

	.toggle .arrow-up {
		display: none;
	}

	.open .toggle .arrow-up {
		display: inline-block;
	}

	.open .toggle .arrow-down {
		display: none;
	}

	.suggestion-list li {
		cursor: pointer;
		padding:0.25em;
	}

	.suggestion-list li:hover {
		color: #fff;
		background-color: #ccc;
	}

	li.active {
		background: #fc0;
	}

</style>

