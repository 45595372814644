<template>
	<div id="formsContainer">


		<transition name="fade" mode="out-in">








			<page-form :title="$t('pageTitles.maxReached')" :grid="false" v-if="currentPage==='maxreached'" key="maxreached">
				<template v-slot:content>
					<!-- ================================================ -->
					<div class="col4 simpletext">
						<p>{{$t('pageLitteracy.maxReached')}}</p>
					</div>
					<!-- ================================================ -->
				</template>
			</page-form>










			<page-form :grid="true" v-else-if="currentPage==='inscription'" key="inscription">
				<template v-slot:content>


					<breadcrumbs
					class="col4 steps4"
					:steps="['registration','store','form','cashback']"
					:currentStep="0"
					></breadcrumbs>


					<!-- ======== CUSTOMER CONTENT FR ======== -->
					<div class="centeredContent col4" v-if="$i18n.locale === 'fr_BE' || $i18n.locale === 'fr_LU'">
						<div class="encart">
							Artechol NG,<br>
							Profitez du futur,<br>
							sainement.
						</div>

						<div class="intro">
							<p>
								Il est important de maintenir un équilibre normal du taux de cholestérol. En plus d’une alimentation saine et d’un mode de vie raisonnable, il est parfois conseillé de prendre une disposition de soutien telle qu’ARTECHOL NG.
							</p>

							<p>
								ARTECHOL NG est une solution unique qui contient de la sauge (Salvia officinalis), de la Tinospora cordifolia, de la coriandre (Coriandrum sativum), de la thiamine, la bactérie Lactobacillus rhamnosus Lipo671<sup>®</sup> et de la poudre de riz fermenté par la levure rouge qui contient naturellement de la monacoline K.
							</p>

							<p>
								ARTECHOL NG contribue à une fonction cardiaque normale et aide à maintenir une cholestérolémie normale, une digestion normale des lipides et la santé du foie.
							</p>

							<p>
								ARTECHOL NG ne contient ni lactose ni gluten.
							</p>
						</div>

						<a class="btn-link" id="discoverlink" href="https://artechol-ng.com/">{{$t('navigation.toCustomerWebsite')}}</a>
					</div>
					<!-- ======== CUSTOMER CONTENT NL ======== -->
					<div v-else class="centeredContent col4">
						<div class="encart">
							Artechol NG,<br>
							Geniet van de toekomst,<br>
							gezond.
						</div>
						<div class="intro">

							<p>
								Het is belangrijk om een normale cholesterolbalans te handhaven. Naast een gezonde voeding en een verstandige levensstijl is het soms raadzaam een ondersteunende maatregel te nemen zoals ARTECHOL NG.
							</p>

							<p>
								ARTECHOL NG is een unieke oplossing die salie (Salvia officinalis), Tinospora cordifolia, koriander (Coriandrum sativum), thiamine, de bacterie Lactobacillus rhamnosus Lipo671<sup>®</sup> en rode gist gefermenteerd rijstpoeder bevat, dat van nature monacoline K bevat.
							</p>

							<p>
								ARTECHOL NG draagt bij tot een normale hartfunctie en helpt een normaal cholesterolgehalte, een normale vetvertering en een gezonde lever te handhaven.
							</p>

							<p>
								ARTECHOL NG is lactose- en glutenvrij.
							</p>


						</div>

						<a class="notmobile btn-link" id="discoverlink" href="https://artechol-ng.com/">Ontdek Artechol NG</a>
						<a class="onlymobile btn-link" id="discoverlink" href="https://artechol-ng.com/">Ontdek Artechol NGe</a>
					</div>
					<!-- ======== END CUSTOMER CONTENT ======== -->


					<claim-blocs
					class="col4"
					:bloc-type="['email', 'whatsapp', 'sms']"
					@goto="goto"
					@mean="setInscriptionMean"
					:lang="$i18n.locale.slice(0,2)"
					:idLead="lead.id"
					>
					<template v-slot:title>
						<headings titletype="titleh3" class="col4">
							<template v-slot:text>{{$t('sectionTitles.inscription')}}	</template>
						</headings>
					</template>
				</claim-blocs>



				<div class="disclaimer col4">
					{{$t('pageLitteracy.registration.byRegistering')}}
					<a v-if="$i18n.locale==='fr_BE' || $i18n.locale==='fr_LU'" href="https://www.astel-medica.com/var/pdf/mentions-legales.pdf">{{$t('pageLitteracy.registration.privacyNotice')}}</a>

					<a v-else href="https://www.astel-medica.com/var/pdf/juridische-informatie.pdf">{{$t('pageLitteracy.registration.privacyNotice')}}</a>


				</div>
				<!-- ================================================ -->
			</template>
		</page-form>


















		<page-form :title="$t('pageTitles.confirmation')" :grid="true" v-else-if="currentPage==='confirminscription'" key="confirminscription">
			<template v-slot:content>
				<!-- ================================================ -->

				<headings titletype="titleh3" class="col4">
					<template v-slot:text>{{$t('pageLitteracy.confirmInscription.thanks')}}</template>
				</headings>

				<spacer :h="1" class="col4"></spacer>

				<div id="confirmSMS" v-if="inscriptionMean==='phone'" class="col4">
					<p>{{$t('pageLitteracy.confirmInscription.smsLine1')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.smsLine2')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.smsLine3')}}</p>
				</div>

				<div id="confirmWhatsapp" v-if="inscriptionMean==='whatsapp'" class="col4">
					<p>{{$t('pageLitteracy.confirmInscription.whatsappLine1')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.whatsappLine2')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.whatsappLine3')}}</p>
				</div>

				<div id="confirmMAIL" v-if="inscriptionMean==='email'" class="col4">
					<p>{{$t('pageLitteracy.confirmInscription.mailLine1')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.mailLine2')}}</p>
					<p>{{$t('pageLitteracy.confirmInscription.mailLine3')}}</p>
				</div>


				<!-- ================================================ -->
			</template>

		</page-form>

















		<page-form :title="$t('pageTitles.wrongCode')" :grid="true" v-else-if="currentPage==='wrongcode'" key="wrongcode">
			<template v-slot:content>
				<!-- ================================================ -->
				<div class="col4 simpletext">
					<p>{{$t('pageLitteracy.wrongCode')}}</p>
				</div>
				<!-- ================================================ -->
			</template>

			<template v-slot:nav>
				<nav-buttons :next="{label:$t('navigation.signup'), dest:'inscription'}" @goto="goto"></nav-buttons>
			</template>

		</page-form>










































		<page-form :title="$t('pageLitteracy.landing.mainTitle')" :grid="true" v-else-if="currentPage==='landing'" key="landing">
			<template v-slot:content>


				<breadcrumbs :steps="['registration','store','form','cashback']" :currentStep="2" class="col4 steps4"></breadcrumbs>


				<p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;">*</span> {{$t('inputLabel.mandatoryFields')}}</p>
				<headings titletype="titleh3" class="col4">
					<template v-slot:text>{{$t('pageLitteracy.landing.aboutYou')}}</template>
				</headings>

				<custom-select
				fieldname="title"
				:placeholder="$t('inputLabel.title.placeholder')"
				:label="$t('inputLabel.title.label')"
				data-from="titles"
				identifier="code"
				description="description"
				:idLead="lead.id"
				:helper="$t('inputLabel.title.helper')"
				:isRequired="true"
				:isReparticipation="reparticipation"
				v-model.lazy:modelValue='fieldsValues.idTitle'
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				></custom-select>

				<spacer :h="0" class="col4"></spacer>

				<custom-input
				fieldname="firstName"
				:placeholder="$t('inputLabel.firstname.placeholder')"
				inputType="text"
				:idLead="lead.id"
				:label="$t('inputLabel.firstname.label')"
				:isRequired="true"
				:helper="$t('inputLabel.firstname.helper')"
				v-model.lazy:modelValue="fieldsValues.firstname"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class=""></custom-input>

				<custom-input
				fieldname="lastName"
				:placeholder="$t('inputLabel.lastname.placeholder')"
				inputType="text"
				:idLead="lead.id"
				:label="$t('inputLabel.lastname.label')"
				:isRequired="true"
				:helper="$t('inputLabel.lastname.helper')"
				v-model.lazy:modelValue="fieldsValues.lastname"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class=""></custom-input>

				<custom-input
				fieldname="email"
				:placeholder="$t('inputLabel.email.placeholder')"
				inputType="text"
				:idLead="lead.id"
				:label="$t('inputLabel.email.label')"
				:helper="$t('inputLabel.email.helper')"
				:isRequired="true"
				:disabled="isMailFieldDisabled"
				v-model.lazy:modelValue="fieldsValues.email"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				></custom-input>

<!--				<custom-input
				fieldname="phoneNumber1"
				:placeholder="$t('inputLabel.phone.placeholder')"
				inputType="tel"
				:idLead="lead.id"
				:label="$t('inputLabel.phone.label')"
				:helper="$t('inputLabel.phone.helper')"
				:isRequired="false"
				:disabled="isPhoneFieldDisabled"
				v-model.lazy:modelValue="fieldsValues.phone1"
				></custom-input>-->

				<!-- All formats supported now -->
				<phone-input
				fieldname="phoneNumber1"
				v-model="phoneClientinput"
				:idLead="lead.id"
				:label="$t('inputLabel.phone.label')"
				:placeholder="$t('inputLabel.phone.placeholder')"
				:helper="''"
				:isRequired="false"
				:disabled="isPhoneFieldDisabled"
				@internationalNumber="setPhone"
				></phone-input>


				<custom-input
				fieldname="IBAN"
				:placeholder="$t('inputLabel.iban.placeholder')"
				inputType="text"
				:idLead="lead.id"
				:label="$t('inputLabel.iban.label')"
				:helper="$t('inputLabel.iban.helper')"
				:isRequired="true"
				v-model.lazy:modelValue="fieldsValues.iban"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class=""></custom-input>

				<spacer :h="1" class="col4"></spacer>

				<headings titletype="titleh3" class="col4">
					<template v-slot:text>{{$t('pageLitteracy.landing.aboutProduct')}}</template>
				</headings>

				<!-- What product has been bought -->
				<popitem
				fieldname="selectProduct"
				:placeholder="$t('inputLabel.product.placeholder')"
				:label="$t('inputLabel.product.label')"
				data-from="popitems"
				identifier="id"
				description="description"
				:idLead="lead.id"
				:helper="$t('inputLabel.product.helper')"
				:isRequired="true"
				:isReparticipation="reparticipation"
				v-model.lazy:modelValue='fieldsValues.popitem'
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2"
				></popitem>

				<!-- Spacer required to force a return to the next line -->
				<spacer :h="0" class="col4"></spacer>

				<meta-custom-select
				fieldname="idLeadTMD1"
				:placeholder="$t('inputLabel.dropdown.placeholder')"
				:data-from="config.enquete.question1"
				:idLead="lead.id"
				helper=""
				:isRequired="false"
				:isReparticipation="reparticipation"
				v-model.lazy:modelValue="fieldsValues.survey.answersSQ1"
				@runCheck="checkIfMustUnlockClaimCashbackButton"
				class="col2">
			</meta-custom-select>


			<!-- This field appears if "autre/andere" is selected -->
			<custom-input
			v-if="fieldsValues.survey.answersSQ1 === parseInt(config.enquete.othervalue1) || fieldsValues.survey.answersSQ1 === config.enquete.othervalue1"
			fieldname="lorem"
			:placeholder="$t('temporary.leadTMD1alt.placeholder')"
			inputType="text"
			:idLead="lead.id"
			:label="$t('temporary.leadTMD1alt.label')"
			:isRequired="fieldsValues.survey.answersSQ1 === parseInt(config.enquete.othervalue1) || fieldsValues.survey.answersSQ1 === config.enquete.othervalue1"
			:helper="$t('temporary.leadTMD1alt.helper')"
			v-model.lazy:modelValue="fieldsValues.survey.answersSQ1alt"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			class="col2"></custom-input>

			<!-- Spacer required to force a return to the next line -->
			<spacer :h="0" class="col4"></spacer>



			<meta-custom-select
			fieldname="idLeadTMD2"
			:placeholder="$t('inputLabel.dropdown.placeholder')"
			:data-from="config.enquete.question2"
			:idLead="lead.id"
			helper=""
			:isRequired="false"
			:isReparticipation="reparticipation"
			v-model.lazy:modelValue="fieldsValues.survey.answersSQ2"
			@runCheck="checkIfMustUnlockClaimCashbackButton"
			class="col2"
			>
		</meta-custom-select>


		<!-- This field appears if "autre/andere" is selected -->
		<custom-input
		v-if="fieldsValues.survey.answersSQ2 === parseInt(config.enquete.othervalue2) || fieldsValues.survey.answersSQ2 === config.enquete.othervalue2"
		fieldname="ipsum"
		:placeholder="$t('temporary.leadTMD2alt.placeholder')"
		inputType="text"
		:idLead="lead.id"
		:label="$t('temporary.leadTMD2alt.label')"
		:isRequired="fieldsValues.survey.answersSQ2 === parseInt(config.enquete.othervalue2) || fieldsValues.survey.answersSQ2 === config.enquete.othervalue2"
		:helper="$t('temporary.leadTMD2alt.helper')"
		v-model.lazy:modelValue="fieldsValues.survey.answersSQ2alt"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		class="col2"></custom-input>

		<!-- Spacer required to force a return to the next line -->
		<spacer :h="0" class="col4"></spacer>


		<meta-custom-select
		fieldname="idLeadTMD3"
		:placeholder="$t('inputLabel.dropdown.placeholder')"
		:data-from="config.enquete.question3"
		:idLead="lead.id"
		helper=""
		:isRequired="false"
		:isReparticipation="reparticipation"
		v-model.lazy:modelValue="fieldsValues.survey.answersSQ3"
		@runCheck="checkIfMustUnlockClaimCashbackButton"
		class="col2"
		>
	</meta-custom-select>





	<!-- Proof of purchase -->
	<headings titletype="titleh3" class="col4">
		<template v-slot:text>{{$t('pageLitteracy.landing.popTitle')}}</template>
	</headings>
	<upload
	post-upload-url-prop="/participations/upload"
	:idLead="lead.id"
	:isRequired="true"
	@runCheck="checkIfMustUnlockClaimCashbackButton"
	></upload>

	<spacer :h="1" class="col4"></spacer>


	<!-- Custom Checkbox General Conditions -->
	<custom-checkbox
	fieldname="conditions"
	inputid="demoConditionsCB"
	helper=""
	:isRequired="true"
	:idLead="lead.id"
	v-model="fieldsValues.cg"
	@runCheck="checkIfMustUnlockClaimCashbackButton"
	special-style="vcheck"
	class="col2"
	>
	<template v-slot:msg>
		{{$t('inputLabel.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openConditions()" download>{{$t('inputLabel.conditions.link')}}</a>
	</template>
</custom-checkbox>

<!-- Custom Checkbox Optin -->
<custom-checkbox
fieldname="optin"
inputid="optin"
:helper="$t('inputLabel.optin.helper')"
v-model="fieldsValues.optin"
special-style="vcheck"
:idLead="lead.id"
class="col2">
<template v-slot:msg>{{$t('inputLabel.optin.label')}}</template>
</custom-checkbox>


<messages :msgType="messageType" class="col4" v-if="isThereMessage">
	<template v-slot:text>
		<ul>
			<li v-for="(message, index) in messageContent" :key="index">&rtrif; {{message}}</li>
		</ul>
	</template>
</messages>


</template>


<template v-slot:nav>
	<nav-buttons @goto="goto">
		<template v-slot:customButton>
			<button @click="sendParticipation, setSurvey('N')" :disabled="!isClaimCashbackButtonActive">{{$t('navigation.claimCashback')}}</button>
		</template>
	</nav-buttons>
</template>

</page-form>































<page-form :title="$t('pageLitteracy.choiceSurvey.title')" :grid="true" v-else-if="currentPage==='choicesurvey'" key="choicesurvey">
	<template v-slot:content>
		<breadcrumbs :steps="['registration','store','form','survey','cashback']" :currentStep="3" class="col4 steps5"></breadcrumbs>
		<headings titletype="titleh3" class="col4">
			<template v-slot:text>{{$t('pageLitteracy.choiceSurvey.fillSurveyIncite')}}</template>
		</headings>
	</template>
	<template v-slot:nav>

		<nav-buttons @goto="goto" class="specialInversion">
			<template v-slot:customButton>
				<button @click="goto('survey'); setSurvey('Y');" :disabled="skipButtonIsClicked" class="btnsurvey">{{$t('navigation.doSurvey')}}</button>
				<button @click="setSurvey('N')" :disabled="skipButtonIsClicked" class="btnskip">{{$t('navigation.skipSurvey')}}</button>
				<button @click="goto('landing');" class="btnsurvey">{{$t('navigation.previous')}}</button>
			</template>
		</nav-buttons>
	</template>
</page-form>














<page-form :title="$t('pageTitles.alreadySend')" :grid="true" v-else-if="currentPage==='participated'" key="participated">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.alreadySend')}}</p>
		</div>

	</template>
</page-form>








<page-form :title="$t('maintenance.title')" :grid="false" v-else-if="currentPage==='maintenance'" key="maintenance">
	<template v-slot:content>
		<div style="text-align:center;">
			<p>{{$t('maintenance.line1')}}</p>
			<p>{{$t('maintenance.line2')}}</p>
			<p>{{$t('maintenance.thanks')}}</p>
		</div>
	</template>
</page-form>










<page-form :title="$t('pageTitles.beforeStart')" :grid="true" v-else-if="currentPage==='beforeStart'" key="beforeStart">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.beforeStart.text')}} {{actionStartDate}}.</p>
		</div>

	</template>
</page-form>






<page-form :title="$t('pageTitles.actionEnded')" :grid="true" v-else-if="currentPage==='actionEnded'" key="actionEnded">
	<template v-slot:content>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.actionEnded.text')}}</p>
		</div>

	</template>
</page-form>
















<page-form :title="$t('pageLitteracy.confirmation.title')" :grid="true" v-else-if="currentPage==='confirmation'" key="confirmation">
	<template v-slot:content>

		<breadcrumbs :steps="['registration','store','form','cashback']" :currentStep="4" class="col4 steps4"></breadcrumbs>

		<div class="col4 simpletext">
			<p>{{$t('pageLitteracy.confirmation.textes.p1')}}</p>
			<p>{{$t('pageLitteracy.confirmation.textes.p2')}}</p>
			<p>{{$t('pageLitteracy.confirmation.textes.p3')}}</p>
			<p>
				<em>{{$t('pageLitteracy.confirmation.textes.p4')}}</em>
			</p>
		</div>

	</template>
</page-form>





</transition>



</div>
</template>



















<!-- ================================================================================== -->

<script>

	import PageForm from '@/components/PageForm.vue';
	import Messages from '@/components/Messages.vue';
	import NavButtons from '@/components/NavButtons.vue';
	import ClaimBlocs from '@/components/ClaimBlocs.vue';
	import Headings from '@/components/Headings.vue';
	import CustomInput from "@/components/CustomInput";
	import CustomCheckbox from '@/components/CustomCheckbox.vue';
	import CustomSelect from '@/components/CustomSelect.vue';
	import MetaCustomSelect from '@/components/MetaCustomSelect.vue';
	import Popitem from '@/components/Popitem.vue';
	// import CustomRadio from '@/components/CustomRadio.vue';
	import Spacer from '@/components/Spacer.vue';
	import Breadcrumbs from '@/components/Breadcrumbs.vue';
	import Upload from "@/components/Upload";
	// import SurveyCustomSelect from '@/components/SurveyCustomSelect.vue';
	import pixel from "../mixins/pixel";
	import config from "../config";
	import PhoneInput from "../components/PhoneInput";
	export default {
		name:"Form",
		mixins: [pixel],
		data () {
			return {

				oauth: {
					token_type:'',
					access_token:'',
					expires_in:''
				},
				skipButtonIsClicked: false,
				isClaimCashbackButtonActive: false,
				inscriptionMean : '',
				lead : {	},
				idLead : config.idlead, 
				config: config,
				currentPage : "home",
				keyFromUrl: '',
				ownerData: {},
				phoneClientinput: '',
				reparticipation: false,
				survey: false,
				dateMessage: null,

				isThereMessage: false,
				messageContent: [],
				messageType: "alert",

				isMailFieldDisabled: false,
				isPhoneFieldDisabled: false,

				fieldsValidity: {
					firstName: false,
					lastName: false,
					email: false,
					conditions: false,
					file: false,
					// selectProduct: false,
				},

				surveyMustBeFilled : 'N',
				fieldsValues : {
					bic:'',
					iban:'',
					idTitle: '',
					idGenre: '',
					uniqueCode : '',
					firstname: '',
					lastname: '',
					email: '',
					popitem: '',
					popitem2: {
						code: '',
						description: ''
					},

					retailer: '',
					language: 'FR',
					birthdate : '',
					phone1: '',
					phone2: '',
					adress: {
						adr1 : '',
						adr2 : '',
						number : '',
						box : '',
						country : 'BE',
						postalcode : '',
						town : ''
					},
					societe1 : '',
					societe2 : '',
					vatCountry: '',
					vatCode : '',
					confidentiality :'',
					cg : '',
					optin: null,
					survey:{
						answersSQ1: '',
						answersSQ1alt: '',
						answersSQ2: '',
						answersSQ2alt: '',
						answersSQ3: '',
					}
				}
			};
		},

		computed: {

			actionStartDate : function(){
				// use registrationbegindate instead ? YES
				let isoRegistrationStartDate = new Date(this.lead.registrationbegindate);
				console.log(isoRegistrationStartDate);
				let m = isoRegistrationStartDate.getMonth();
				let d = isoRegistrationStartDate.getDay();
				let y = isoRegistrationStartDate.getFullYear();
				let humanReadableStartDate = `${d}/${m}/${y}`;
				return humanReadableStartDate;
			},

			actionEndDate : function(){
				return this.formatDate(this.lead.lastnewparticipationdate);
			},

			isMaxParticipationReached: function(){
				console.log("--------------------------");
				console.log("Il reste " + this.lead.inscriptions_left + " inscriptions disponibles");
				console.log("Il reste " + this.lead.participations_left + " participations disponibles");
				console.log("--------------------------");
				return this.lead.participations_left <= 0;
			},
		},

		components: {
			PhoneInput,
			NavButtons,
			ClaimBlocs,
			Headings,
			Breadcrumbs,
			CustomInput,
			CustomSelect,
			CustomCheckbox,
			MetaCustomSelect,
			Messages,
			Spacer,
			Upload,
			PageForm,
			Popitem
		},

		methods: {
			setPhone(value) {
				console.log(value)
				this.fieldsValues.phone1 = value;
			},

			setSurvey(val){
				this.surveyMustBeFilled = val;
				console.log("Filling survey? " + (val===true)?'yes':'no');
				if (val ==="N"){
					this.sendParticipation();
				}
				this.survey = true;
			},

			setInscriptionMean(mean){
				this.inscriptionMean = mean;
			},


			/* could be rewritten. See computed values actionStartDate and actionEndDate */
			formatDate(oldDate){
				let dateArray = oldDate.split("-");
				return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
			},




			goto(dest){
				console.log("%cI received '" + dest + "' as a destination","color:#999;");
				this.logCustomEvent(dest);
				this.currentPage = dest;
			},

			reload(){
				console.clear();
				this.goto('home');
				this.checkIfMaxParticipation();
			},

			openConditions (){
				let link = "https://cashback.artechol-ng.com/files/conditions_";
				window.open(link + this.$i18n.locale.toLowerCase() +".pdf");
			},

			getKeyFromUrl(){
				console.log("Looking in the URL for a key");
				let urlParams = new URLSearchParams(window.location.search);
				let key = '';
				if (urlParams.has('key')){
					key = urlParams.get('key');
					console.log("--> Found a key: " + key);
					console.log("--------------------------");
				}else{
					console.log("--> Missing key");
					console.log("--------------------------");
				}
				return key;
			},



			/* ==== IDLANGUAGECODE & IDCOUNTRYCODE ==== */
			getIdOfCountry(){
				/* Must get the info from a dropdown, or hardcoded */
				/* On Artechol, it can be 1 (be) or 4 (lu) */
				let currentLoc = this.$i18n.locale.slice(0,2).toUpperCase();
				console.log("Localisation found is: " + currentLoc);
				if (currentLoc === 'be') {
					return 1;
				}
				if (currentLoc === 'lu') {
					return 4;
				}
				// If nothing else is find
				return 1;
			},

			getIdOfLanguage(){
				/* Must return the id based on the currentLocale */
				let defaultIdLang = 2;
				let idLang = defaultIdLang;
				let currentLocale = this.$i18n.locale.slice(0,2).toUpperCase();
				/* this must be replaced by a comparaison with data received from API => See getLocalizingData() */
				if (currentLocale === 'FR'){
					idLang = 1;
				}else{
					idLang = defaultIdLang;
				}
				/* END */
				return idLang;
			},
			/* ==== END IDLANGUAGECODE & IDCOUNTRYCODE ====*/






			/* GETTING THE INFO ABOUT THE ACTION */
			getLead(idLead){
				console.log("Getting Lead for " + this.idLead + "("+ idLead  +") and looking for the key");
				this.axios.get(
					`/lead/${idLead}`
					)
				.then(res => {
					console.log("I managed to get the lead! ");
					console.log(res.data);
					this.lead = res.data;
					// check if action has started/ended and proceed…
					this.checkIfInDate();
				})
				.catch(error => {
					console.error("I cannot get the Lead");
					console.log(error);
					console.log(error?.response?.data?.errors);
				})
				.then(()=>{
				});
			},

			checkIfInDate(){
				let dateOfNow = new Date();
				let actionStartDate = new Date(this.lead.participationbegindate);
				let actionEndDate = new Date(this.lead.participationenddate);

				if(dateOfNow.getTime() > actionStartDate.getTime() &&
					dateOfNow.getTime() < actionEndDate.getTime()){
					console.log("Now is a good time to participate");
				this.checkForKeyInUrl();
			}else if(dateOfNow.getTime() < actionStartDate.getTime()){
				console.log("It's too soon!");
				this.goto('beforeStart');
			}
			else if(dateOfNow.getTime() > actionEndDate.getTime()){
				console.log("It's too late!");
				this.goto('actionEnded');
			}else{
				console.log("Are you travelling in time?");
			}

		},


		checkIfMustUnlockClaimCashbackButton(val){
			console.log("One input is sending an object value:");
			console.log(val);
				// setting the new property :
				this.fieldsValidity[val.fieldname] = val.valid;
				this.$nextTick(function () {
					this.checkAllRequiredFields();
				})
			},

			checkAllRequiredFields(){
				console.group("All Fields on TRUE? --> button unlocked");
				// Names of the fields to check (in this form) : firstName, lastName, email, select_product, select_retailer, cr1, cr2, conditions

				// "conditions" is appart and value must be taken elsewhere:
				this.fieldsValidity.conditions = Boolean(this.fieldsValues.cg);
				let allRequiredFieldsAreValid = true;
				for (let item in this.fieldsValidity){
					// just for console embellishement :
					let color = (this.fieldsValidity[item])?"#090":"#900";
					console.log(`%c ${item}: ${this.fieldsValidity[item]}`, `color:${color}`);
					if (this.fieldsValidity[item]===false){
						allRequiredFieldsAreValid = false;
					}
				}
				// console.log("Mockup test: always TRUE");
				if(allRequiredFieldsAreValid && this.fieldsValues.survey.answers424 != '' && this.fieldsValues.survey.answers417 != ''){
					this.isClaimCashbackButtonActive = true;
					console.log("Button must be unlocked");
				}else{
					this.isClaimCashbackButtonActive = false;
					console.log("Button must be locked");
				}
				console.groupEnd();
			},

			/* ------------------------------ */

			checkForKeyInUrl(){
				this.keyFromUrl = this.getKeyFromUrl();
				if (this.keyFromUrl){
					this.getOwnerData();
				}else{
					if (this.isMaxParticipationReached){
						this.goto('maxreached');
					}else{
						this.goto('inscription');
					}

				}
			},

			/* ------------------------------ */

			checkIfalreadyParticipated(){
				console.log("===================");
				console.log(this.ownerData);
				console.log(this.bigOwnerData);
				console.log("===================");
				/* check if already participated (status === 30) or if reparticipation */
				if (this.ownerData.status !== "30" || this.reparticipation){
					console.log("Participation has not been sent yet or this is a reparticipation.");

					console.log((this.reparticipation)?"(This is a reparticipation)":"(This is not a reparticipation)");



					console.log("--------------------------");
					this.goto('landing');
				}else{
					console.log("Participation already sent and this is not a reparticipation.");
					console.log("--------------------------");
					this.goto('participated');
				}
			},

			/* ------------------------------ */

			putDataInFields(){
				console.group("Putting data into fields");
				if(this.reparticipation){
					console.log("This is a reparticipation");
					let categories = Object.keys(this.bigOwnerData);
					categories.forEach((category) => {

						if(category === 'ParticipationMetaData'){
							this.bigOwnerData[category].forEach((item, index) => {
								this.fieldsValues.survey[`answers${index}`] = item.idleadtmdv;
							});
						}else{
							let keys = Object.keys(this.bigOwnerData[category]);
							if(typeof this.bigOwnerData[category] === 'object'){
								keys.forEach((key) => {
									this.fieldsValues[key] = this.bigOwnerData[category][key];
								});
								console.group("fieldsValues");
								console.log(this.fieldsValues);
								console.groupEnd();
							}
						}
					});
				} else {
					if(this.ownerData.email){
						console.log("I've found an email to put into a field");
						this.fieldsValues.email = this.ownerData.email;
						this.isMailFieldDisabled = true;
						console.log("--------------------------");
					}
					if(this.ownerData.phonenumber1){
						console.log("I've found a phone number to put into a field");
						this.phoneClientinput = this.ownerData.phonenumber1;
						this.isPhoneFieldDisabled = true;
						console.log("--------------------------");
					}
				}
				console.groupEnd();
			},




			/* ===== WIP 1118 ===== */

			getLocalizingData(idlead){
				console.log("Getting localizing data");
				this.axios.get(
					`/dropdown/${idlead}/languages-code`
					)
				.then(res => {
					console.log("Localizing data found");
					this.localizingDataFound(res);
				})
				.catch(error => {
					console.error("Localizing data not found");
					this.localizingDataNotFound(error);
				});
			},



			localizingDataFound(res){
				/* TODO - This one will be used to display switch lang buttons */
				/* TODO - It can be used to get the correspondance between locale and id to be sent */

				console.groupCollapsed("WIP - WIP - WIP - WIP")
				console.log(res.data);
			// I receive an array with an entry for each case
			for (let item in res.data){
				console.log(res.data[item].countrycodereference.code);
				console.log(res.data[item].countrycodereference.id);
				console.log(res.data[item].languagecodereference.code);
				console.log(res.data[item].languagecodereference.id);
			}

			let temp = this.getIdOfLanguage();
			console.log("Je renvoie " + temp + " comme id de lang");
			console.groupEnd();
		},








		localizingDataNotFound(error){
			console.log(error);
		},

		/* ===== /WIP 1118 ===== */





		/* ------------------------------ */

		getOwnerData(){
			console.group("Get Owner Data");
			this.axios.get(
				`/participationOwner/${this.keyFromUrl}?lang=${this.$i18n.locale.slice(0,2)}`
				)
			.then(res => {
				console.log("I managed to get an answer for the owner ID: ");

				if (res.data){
					if(Array.isArray(res.data)){
							// If res is a vue proxy
							console.log("res.data is an array:");
							console.log(res.data[0]);

							// reparticipation case
							if(res.data[0].reparticipation){
								this.ownerData = res.data[0]["ParticipationOwner"];
								this.bigOwnerData = res.data[0];
								this.reparticipation = true;
							}else{
								this.ownerData = res.data[0];
							}

						}else{
							// if res is an object
							console.log("res.data is an object:");
							console.log(res.data);
							// reparticipation case
							if(res.data.reparticipation) {
								this.ownerData = res.data["ParticipationOwner"];
								this.bigOwnerData = res.data;
								this.reparticipation = true;
							} else {
								this.ownerData = res.data;
							}
						}

						// So we have ownerData. Now…

						this.setLang(this.ownerData.idlanguagecode);

						if(this.isMaxParticipationReached && !this.reparticipation){
							this.goto('maxreached');
						}else{
							this.putDataInFields();
						}
						// Continue participation process…
						this.checkIfalreadyParticipated();

					}else{
						console.log("there is no participation owner for this key");
						this.goto('wrongcode');
					}
				})
			.catch(error => {
				console.error("Sorry, no answer from the API");
				console.log(error.response.data.errors);
				console.log("--------------------------");
				this.goto('wrongcode');
			})
			.then(()=>{
				console.groupEnd();
			});
		},

		setLang (id) {
			let langs = ['en', 'fr', 'nl'];
			console.log("::: Change lang to: "+ langs[id] + " :::");
			if (id == '1'){
				this.$i18n.locale = 'fr_BE';
			}else{
				this.$i18n.locale = 'nl_BE';
			}
			this.$store.dispatch('setIdLanguageCode', id);
		},

		composeData(){
			let data = {};
			/* prep of the DATA */

			data = {
				"Authorization" : this.oauth.token_type + ' ' + this.oauth.access_token,
				"idLeadManagement": this.lead.id,
				"ParticipationOwner": {
					"idParticipationOwner": this.ownerData.id,
					"idTitle": this.fieldsValues.idtitle,
					"idGender": 3,
					"phoneNumber1": this.fieldsValues.phone1,
					"lastName": this.fieldsValues.lastname,
					"firstName": this.fieldsValues.firstname,
					"email": this.fieldsValues.email,
					"IBAN": this.fieldsValues.iban,
					"optin": this.fieldsValues.optin,
					"generalCondition": this.fieldsValues.cg,
					"countryCode": this.fieldsValues.adress.country,
					"ISOLanguageCode": this.$i18n.locale.slice(0,2),
					"idLanguageCode": this.getIdOfLanguage(),
					"idCountryCode": this.getIdOfCountry(),
				},
				"Participation": {
					"idParticipationType": 1,
					'fingerprint': 'v2.cfAGmgqRuc1r8C3rr3GK.vfPuVjq0wC3BMTlg_606174c1705d61.27931079'
				},
				"ProofOfPurchaseDetailConsumer": {
					"idPOPItem": this.fieldsValues.popitem
				},
				"ProofOfPurchaseHeaderConsumer": {
					"idPOPRetailer": this.fieldsValues.retailer,
					"freeRetailerName" : this.fieldsValues.freeRetailer,
					"purchaseDate" : this.fieldsValues.purchaseDate,
				},
				"ParticipationMetaData": [
				{
					"idLeadTMD": 1,
					"idLeadTMDV": this.survey ? 1 : 2
				}
				]
			};










			/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
			/* META QUESTIONS ==== HOW TO AUTOMATE THIS ? */
			if(this.fieldsValues.survey.answersSQ1 !== '') {
				/* dropdown */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question1,
					"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ1)
				});
			}
			if(this.fieldsValues.survey.answersSQ1alt !== '') {
				/* Input free text */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question1,
					"idLeadTMDV" : '',
					"answer": this.fieldsValues.survey.answersSQ1alt
				});
			}

			if(this.fieldsValues.survey.answersSQ2 !== '') {
				/* dropdown */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question2,
					"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ2)
				});
			}
			if(this.fieldsValues.survey.answersSQ2alt !== '') {
				/* Input free text */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question2,
					"idLeadTMDV" : '',
					"answer": this.fieldsValues.survey.answersSQ2alt
				});
			}

			if(this.fieldsValues.survey.answersSQ3 !== '') {
				/* dropdown */
				data.ParticipationMetaData.push({
					"idLeadTMD" : config.enquete.question3,
					"idLeadTMDV" : parseInt(this.fieldsValues.survey.answersSQ3)
				});
			}
			/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */












			return data;
		},

		sendParticipation(){
			/* deactive button and sending form => only if field e-mail OR phone is validated ! */
			/* There will be lost clicks… */
			this.isClaimCashbackButtonActive = false;

			let data = this.composeData();
			console.log("Before posting, what did I received from composeData() ?");
			console.log(data);



			/* send fieldsvalues to API */
			this.axios.post(
				`/participation`,
				data
				)
			.then(res => {
				console.log("I managed to POST the participation! ");
				console.log(res.data);
				this.goto('confirmation');
			})
			.catch(error => {
				console.log("An error has occured: ");
					console.log(error); // for linter to be happy

					// if "notifications" key is present
					if(error.response?.data?.notifications){
						this.displayMessage(error.response.data.notifications, 'alert');
					}
					/* reactivate buttons to allow re-submission */
					this.isClaimCashbackButtonActive = true;
				});
		},

		displayMessage(message, mode='info') {
			console.log("Displaying message");
			this.isThereMessage = true;
			this.messageContent = message;
			this.messageType = mode;
		},

		hideMessage() {
			this.isThereMessage = false;
		},

		checkMaintenance() {
			console.log('====Maintenance Check====')
			let status = this.$store.getters.getMaintenance;
			console.log(status);
			if(status === true) {
				this.currentPage = 'maintenance';
			}else{
				this.getLocalizingData(this.idLead);
				this.getLead(this.idLead);					
			}
		}

	},

	beforeMount(){
		console.clear();
		this.checkMaintenance();
	},
}

</script>

<!-- ================================================================================== -->
<style lang="scss" scoped>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity .5s, height .5s ease-in-out;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		height:0;
	}

/*
.fade-enter-to,
.fade-leave-from {
	height:100%;
	opacity:1;
}
*/



</style>
