export default {
    data() {
        return {
            gtm: 'GTM-KWJS8W3',
            dc: 'DC-11232842',
            gaHighco: 'UA-208926391-3',
        }
    },
    methods: {
        insertGtmTag() {
            // GTM script
            let script = document.createElement('script');
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-W4X38F5');`;
        document.head.appendChild(script);

            // GTM noscript
            let noscript = document.createElement('noscript');
            noscript.innerHTML = `<iframe src=https://www.googletagmanager.com/ns.html?id=GTM-W4X38F5
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.prepend(noscript);
        },
        insertGTag() {
            let script = document.createElement('script');
            script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.dc}`);
            script.setAttribute('async', '');
            document.head.appendChild(script);

            script = document.createElement('script');
            script.innerHTML = `window.dataLayer = window.dataLayer || []; 
            function gtag(){
                dataLayer.push(arguments);
            } 
            gtag('js', new Date()); 
            gtag('config', '${this.dc}');`;
            let noscript = document.createElement('noscript');
            noscript.innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=11232842;type=invmedia;cat=flood0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';
            document.body.prepend(noscript);
        },
        insertHighcoGaTag() {
            let script = document.createElement('script');
            script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.gaHighco}`);
            script.setAttribute('async', '');
            document.head.appendChild(script);
            script = document.createElement('script');
            script.innerHTML = `window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', '${this.gaHighco}');`;
            document.head.appendChild(script);
        }

    },
    beforeMount() {
       /*
        this.insertGTag();
        */
        this.insertGtmTag();
        this.insertHighcoGaTag();
    }
}
