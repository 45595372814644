export default {
    data () {
        return {
            example: 'Highco Data',
        }
    },
    methods: {
        helloWorld() {
            console.log('MIXINS CAN HAVE METHODS');
        }
    },
    computed: {
        componentNameLength: function () {
            return this.name.length;
        }
    },
    created() {
        console.log('demo mixin loaded');
    }
}
