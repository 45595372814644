<template>
    <div>

        <heading titletype="titleh2"><template v-slot:text>{{$t('general.world')}}</template></heading>

        <section>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime sapiente nulla, dolore voluptatem fuga sint ipsum labore, ad perspiciatis voluptate doloremque nostrum omnis quasi reprehenderit itaque. Expedita, nobis maxime maiores.
        </section>

        <login-vuex-store></login-vuex-store>

    </div>  
</template>

<script>
    import LoginVuexStore from "@/examples/LoginVuexStore";
    import Headings from '@/components/Headings.vue';

    export default {
        name: "Home",
        components: {
            LoginVuexStore,
            'heading': Headings,
        },
}
</script>

<style>
</style>
