import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Form from '../views/Form.vue'
import Dev from '../views/Dev.vue'
import About from '../views/About.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
{
  path: '/',
  name: 'FormHome',
  component: Form
},
{
  path: '/home',
  name: 'Home',
  component: Home
},
{
  path: '/form',
  name: 'Form',
  component: Form
},
{
  path: '/about',
  name: 'About',
  component: About
},
{
  path: '/dev',
  name: 'Dev',
  component: Dev
},

{
  path: '/demo',
  name: 'array',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/DemoPage')
  },
  {
    path: '/:notFound(.*)',
    component: NotFound
  }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

/*  router.beforeEach((from, to, next) => {
    // route location you come from => you could extract url from this object
    console.log(from);
    // route location you go to => you could extract url from this object
    console.log(to);

    // Do your pixel magic here using the global object "fbq(do something)"
      // Sometimes adblockers will block your pixel import and your Vue app might crash.
      // => Added check to run pixelcode only when it is defined
      if (typeof fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('track', 'PageView');
      }
    // use next to continue the navigation after your pixel operation is done
    next();
  });*/

  export default router
