<template>
	<div class="navbuttonsbar">
		<button v-if="previous" @click="$emit('goto', previous.dest)">{{previous.label}}</button>
		<slot name="customButton"></slot>
		<button v-if="next" @click="$emit('goto', next.dest)" :disabled="next.disabled">{{next.label}}</button>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"NavButtons",
		props : {
			previous : {
				type: Object,
				required: false
			},
			next : {
				type: Object,
				required: false
			}
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
