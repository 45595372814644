import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store/store";
import i18n from "./i18n/i18n";
import '@/assets/sass/app.scss';
import VueAxios from 'vue-axios';
//import {Axios} from "./components/AxiosClass";
import config from "./config";
import { AxiosBuilder } from "./components/AxiosBuilder";

const baseConf = {
  baseUrl: config.apiUrl,

  customHeaders: {
    'Accept' : 'application/json',
    'Content-Type': 'application/json'
  }
}

new AxiosBuilder(baseConf).build().then((axiosImproved) => {
  const app = createApp(App)
  .use(router)
  .use(VueAxios, axiosImproved)
  .use(store)
  .use(i18n)
  .mount('#app')

  app.$store.commit('SET_LOCALE',app.$i18n.locale)
})
