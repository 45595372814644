<template>
	<!--<div class="tolang" @click="$i18n.locale=tolang" :class="{active:isActive}">-->
		<div class="tolang" @click="switchLang(tolang)" :class="{active:isActive}">
			{{label}}
		</div>
	</template>

	<!-- ================================================================================== -->

	<script>

		export default {
			name:"LangButton",

			props : {
				label : {
					type: String,
					required: true
				},
				tolang : {
					type: String,
					required: true
				},
				isActive : {
					type: Boolean,
					required: true,
					default: false
				}
			},

			methods: {
				switchLang(locale) {
					this.$i18n.locale = locale;
					this.$store.dispatch('setLocale', locale);
				}
			}
		}

	</script>

	<!-- ================================================================================== -->

	<style lang="scss" scoped>
	</style>
