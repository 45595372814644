import {createStore} from "vuex";

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
	modules: {

	},
	state: {
		name: null,
		locale: null,
		idlanguagecode : 2,
		fingerprint: null,
		bearerToken: null,
		maintenance: false,
	},
	mutations: {
		setName (state, name) {
			state.name = name;
		},
		clearName (state) {
			state.name = null;
		},
		SET_LOCALE (state, locale) {
			state.locale = locale;
		},
		SET_FINGERPRINT (state, fingerprint) {
			state.fingerprint = fingerprint;
		},
		SET_BEARER_TOKEN(state, bearerToken) {
			state.bearerToken = bearerToken;
		},
		SET_MAINTENANCE(state, value) {
			state.maintenance = value;
		},
		setIdLanguageCode (state, id){
			state.idlanguagecode = id;
		},
	},
	/* Actions can be asynchronous */
	/* commit takes the name of the mutation it triggers */
	actions: {
		setName (context, name) {
			context.commit('setName', name);
		},
		clearName (context) {
			context.commit('clearName');
		},
		setLocale (context, locale) {
			console.log("I set store locale to " + locale);
			context.commit('SET_LOCALE', locale);
		},
		setFingerprint (context, payload) {
			console.log(payload)
			context.commit('SET_FINGERPRINT', payload.fingerprint);
		},
		setBearerToken(context, payload) {
			console.log(payload);
			context.commit("SET_BEARER_TOKEN", payload.bearerToken);
		},
		setMaintenance(context, payload) {
			context.commit("SET_MAINTENANCE", payload)
		},
		setIdLanguageCode (context, id){
			console.log("Store SET idlanguagecode to " + id);
			context.commit('setIdLanguageCode', id);
		},
	},
	getters: {
		getName: (state) => {
			return state.name;
		},
		getLang: (state) => {
			console.log("Lang is asked");
			return state.locale.slice(0,2);
		},
		getLocale: (state) => {
			console.log("Locale is asked");
			return state.locale;
		},
		getFingerprint: (state) => {
			return state.fingerprint;
		},
		getBearerToken: (state) => {
			return state.bearerToken;
		},
		getMaintenance: (state) => {
			return state.maintenance;
		},
		getIdLanguageCode: (state) => {
			return state.idlanguagecode;
		},
	},
	strict: debug,
});
