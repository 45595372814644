<template>
	<div class="langswitcher">
		<lang-button label="Belgique" tolang="fr_BE" :isActive="$i18n.locale === 'fr_BE'"></lang-button>
		<lang-button label="België" tolang="nl_BE" :isActive="$i18n.locale === 'nl_BE'"></lang-button>
		<lang-button label="Luxembourg" tolang="fr_LU" :isActive="$i18n.locale === 'fr_LU'"></lang-button>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import LangButton from '@/components/LangButton';
	export default {
		name:"langswitcher",
		
		components: {
			LangButton 
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
