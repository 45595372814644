export default {
    props: {
        fieldname: {
            type: String,
            required: true
        },
        placeholder : {
            type: String,
            required : false,
        },
        helper : {
            type: String,
            required: false
        },
        isRequired : {
            type: Boolean,
            required: false,
            default: false
        },
        modelValue: {
            // Support all types
            // subselection of types => type: [String, Number, Boolean, Array, Object],
            // type: String,
            required: true
        },
        idLead: {
          type: Number,
          required: true,
      },
  },
  data() {
    return {
        isInError: false,
        isInSuccess : false,
        isLoading: false,
    }
},
methods: {
    giveError() {
        this.isInError = true;
        this.isInSuccess = false;
    },
    giveSuccess() {
        this.isInError = false;
        this.isInSuccess = true;
    },
    setLoading() {
        this.isLoading = true;
    },
    setNotLoading() {
        this.isLoading = false;
    },
}
}